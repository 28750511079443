export default {
    init() {
        console.log('common');
    },
    finalize() {

        (function ($) {
            'use strict';

            $(document).ready(function () {
                eventListener();

                mug11oz();

                mug15oz();

                if(!!document.querySelector('body.digital-t-shirts')) {
                    /* shirt(); */
                    ShirtPrice();
                }

                if(!!document.querySelector('body.buttons')) {
                    updateButtonPrice();
                }

                if(!!document.querySelector('body.booklets')) {
                    BookletPrice();
                }

                if(!!document.querySelector('body.print-and-bind')) {
                    PrintNBindPrice();
                }

                if(!!document.querySelector('body.color-copies')) {
                    colorCopiesPrice();
                }
                if(!!document.querySelector('body.black-and-white-copies')){
                    bwCopiesPrice();
                }
                PostcardsPrice();
                if(!!document.querySelector('body.bookmarks')) {
                    BookmarksPrice();
                }

                if(!!document.querySelector('body.business-cards')) {
                    updateBusinessCardPrice();
                }

                if(!!document.querySelector('body.engraved-16oz-pub-glass')) {
                    pubGlass16oz();
                }

                if(!!document.querySelector('body.yeti-20-oz-tumbler')) {
                    yetiTumbler();
                }

                if(!!document.querySelector('body.polar-20-oz-tumbler')) {
                    polarTumbler();
                }

            });

            function eventListener() {

                //button event listeners
                $('.button-size').change(updateButtonPrice);
                $('.button-quantity').on('keyup', 'input', updateButtonPrice);

                //business card event listeners
                $('.quantity').find('select').change(updateBusinessCardPrice);
                $('body.business-cards [name="input_19"]').change(updateBusinessCardPrice);
            }

            function pubGlass16oz() {
                console.log('pubglass');

                let quantity = $('body.engraved-16oz-pub-glass [name="input_3"]');

                let finalPrice = $('body.engraved-16oz-pub-glass [name="input_10"]');

                const elements = [quantity];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () {
                        priceUpdatePub();
                    });
                }
                priceUpdatePub();

                function priceUpdatePub() {

                    let price = 0;

                    if (quantity.val() == 1) {

                        price = 12;
                    }
                    else if (quantity.val() > 1 && quantity.val() < 4) {
                        console.log('here');

                        price = 10;
                    }
                    else if (quantity.val() > 3) {

                        price = 9;
                    }

                    finalPrice.val(price).change();
                }
            }

            function yetiTumbler() {

                let quantity = $('body.yeti-20-oz-tumbler [name="input_3"]');

                let finalPrice = $('body.yeti-20-oz-tumbler [name="input_10"]');

                const elements = [quantity];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () {
                        priceUpdatePub();
                    });
                }
                priceUpdatePub();

                function priceUpdatePub() {
                    let price = 0;

                    if (quantity.val() == 1 || quantity.val() == 2 || quantity.val() == 3) {

                        price = 45;
                    }
                    else if (quantity.val() > 3) {

                        price = 37.5;
                    }

                    finalPrice.val(price).change();
                }
            }

            function polarTumbler() {

                let quantity = $('body.polar-20-oz-tumbler [name="input_3"]');

                let finalPrice = $('body.polar-20-oz-tumbler [name="input_10"]');

                const elements = [quantity];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () {
                        priceUpdatePub();
                    });
                }
                priceUpdatePub();

                function priceUpdatePub() {
                    let price = 0;

                    if (quantity.val() == 1 || quantity.val() == 2 || quantity.val() == 3) {

                        price = 30;
                    }
                    else if (quantity.val() > 3) {

                        price = 25;
                    }

                    finalPrice.val(price).change();
                }
            }


            // 11 oz Mug price
            function mug11oz() {
                $('.mug-11oz-quantity').on('keyup', 'input', function () {
                    let total = 0;
                    let quantity = $('.mug-11oz-quantity').find('input').val();
                    if (quantity == 1) {
                        total = quantity * 9.5;
                        $('.mug-11oz-total').html(`$${total.toFixed(2)}`);
                    } else if (quantity > 1 && quantity <= 4) {
                        total = quantity * 8;
                        $('.mug-11oz-total').html(`$${total.toFixed(2)}`);
                    } else if (quantity >= 5 && quantity <= 10) {
                        total = quantity * 7;
                        $('.mug-11oz-total').html(`$${total.toFixed(2)}`);
                    } else if (quantity >= 11) {
                        total = quantity * 6.5;
                        $('.mug-11oz-total').html(`$${total.toFixed(2)}`);
                    }
                })
            }

            // 15 oz Mug price
            function mug15oz() {
                $('.mug-15oz-quantity').on('keyup', 'input', function () {
                    let total = 0;
                    let quantity = $('.mug-15oz-quantity').find('input').val();
                    if (quantity == 1) {
                        total = quantity * 12;
                        $('.mug-15oz-total').html(`$${total.toFixed(2)}`);
                    } else if (quantity > 1 && quantity <= 4) {
                        total = quantity * 11;
                        $('.mug-15oz-total').html(`$${total.toFixed(2)}`);
                    } else if (quantity >= 5 && quantity <= 10) {
                        total = quantity * 9;
                        $('.mug-15oz-total').html(`$${total.toFixed(2)}`);
                    } else if (quantity >= 11) {
                        total = quantity * 8.5;
                        $('.mug-15oz-total').html(`$${total.toFixed(2)}`);
                    }
                })
            }








            function shirt() {
                console.log('in shirt');

                let shirtType = $('.shirt-type');
                let gildanShirtStyle = $('.gildan-shirt-style');
                let genericShirtStyle = $('.generic-shirt-style');

                let quantityS = $('body.digital-t-shirts [name="input_12"]');
                let quantityM = $('body.digital-t-shirts [name="input_18"]');
                let quantityL = $('body.digital-t-shirts [name="input_21"]');
                let quantityXL = $('body.digital-t-shirts [name="input_22"]');
                let quantityXXL = $('body.digital-t-shirts [name="input_25"]');
                let quantityXXXL = $('body.digital-t-shirts [name="input_26"]');

                setAvailableColors(gildanShirtStyle.find('select option:checked').val());

                let selectedColor;
                // console.log('selected color', selectedColor);
                // console.log('selected color', $('body.digital-t-shirts [name="color"]:checked').val());


                const elements = [shirtType, gildanShirtStyle, genericShirtStyle];

                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () {
                        update();
                        // updateShirtColor();
                    });
                }

                // const quantities = [quantityS, quantityS, quantityL, quantityXL, quantityXXL, quantityXXXL];

                // for (let i = 0; i < elements.length; i++) {
                //     quantities[i].on('change paste keyup', function () {
                //         updateShirtColor();
                //     });
                // }

                $('#color-select-container').on('click', '.color-select-label', updateShirtColor);

                function update() {

                    if (shirtType.find('select option:checked').val() == 'Generic') {
                        $('#product-img').attr('src', `/wp-content/uploads/${genericShirtStyle.find('select option:checked').val()}_white.jpg`);
                        setAvailableColors(genericShirtStyle.find('select option:checked').val());
                        console.log('in update');
                    }
                    else {
                        $('#product-img').attr('src', `/wp-content/uploads/${gildanShirtStyle.find('select option:checked').val()}_white.jpg`);
                        setAvailableColors(gildanShirtStyle.find('select option:checked').val());
                        console.log('in update else');
                    }
                }

                // update the shirt color when a new color is selected
                function updateShirtColor() {
                    let shirtURL = $('#product-img').attr('src');
                    let selectedGildanShirtStyle = $('.gildan-shirt-style').find('select option:checked').val();
                    let selectedGenericShirtStyle = $('.generic-shirt-style').find('select option:checked').val();
                    let selectedPrice = 0;
                    let colored = false;
                    let sidesSelected = $('input[name=input_14]:checked').val();
                    let sides = false;

                    selectedColor = $(this).prev().val();

                    if (shirtType.find('select option:checked').val() == 'Generic') {
                        if (selectedGenericShirtStyle == 'onesie') {
                            shirtURL = `/wp-content/uploads/onesie_${selectedColor}.jpg`;
                            $('#product-img').attr('src', shirtURL);
                            $('#product-color-name').empty().append(selectedColor.replace(/_/g, ' '));

                        } else if (selectedGenericShirtStyle == 'toddler') {
                            shirtURL = `/wp-content/uploads/toddler_${selectedColor}.jpg`;
                            $('#product-img').attr('src', shirtURL);
                            $('#product-color-name').empty().append(selectedColor.replace(/_/g, ' '));
                        }
                    }
                    else if (shirtType.find('select option:checked').val() == 'Gildan') {
                        shirtURL = `/wp-content/uploads/${selectedGildanShirtStyle}_${selectedColor}.jpg`;
                        $('#product-img').attr('src', shirtURL);
                        $('#product-color-name').empty().append(selectedColor.replace(/_/g, ' '));

                        if (selectedColor !== 'white') {
                            colored = true;
                        }

                        if (sidesSelected == 'two-sided') {
                            sides = true;
                        }

                        if (selectedGildanShirtStyle == 'crewneck') {
                            selectedPrice = 20;
                            colored ? selectedPrice += 5 : selectedPrice;
                            !colored && sides ? selectedPrice += 15 : selectedPrice;
                            colored && sides ? selectedPrice += 10 : selectedPrice;
                        }

                        if (selectedGildanShirtStyle == 'vneck') {
                            selectedPrice = 25;
                            colored ? selectedPrice += 2 : selectedPrice;
                            !colored && sides ? selectedPrice += 13 : selectedPrice;
                            colored && sides ? selectedPrice += 13 : selectedPrice;
                        }

                        if (selectedGildanShirtStyle == 'longsleeve') {
                            selectedPrice = 25;
                            colored ? selectedPrice += 4 : selectedPrice;
                            !colored && sides ? selectedPrice += 13 : selectedPrice;
                            colored && sides ? selectedPrice += 11 : selectedPrice;
                        }

                        if (selectedGildanShirtStyle == 'ladiescrewneck') {
                            selectedPrice = 25;
                            colored ? selectedPrice += 2 : selectedPrice;
                            !colored && sides ? selectedPrice += 10 : selectedPrice;
                            colored && sides ? selectedPrice += 10 : selectedPrice;
                        }

                        if (selectedGildanShirtStyle == 'ladiesvneck') {
                            selectedPrice = 25;
                            colored ? selectedPrice += 4 : selectedPrice;
                            !colored && sides ? selectedPrice += 10 : selectedPrice;
                            colored && sides ? selectedPrice += 10 : selectedPrice;
                        }

                        if (selectedGildanShirtStyle == 'youth') {
                            selectedPrice = 18;
                            colored ? selectedPrice += 7 : selectedPrice;
                            !colored && sides ? selectedPrice += 7 : selectedPrice;
                            colored && sides ? selectedPrice += 10 : selectedPrice;
                        }

                        console.log(selectedGildanShirtStyle, selectedPrice, sides);

                        $('.gildan-dynamic-price').find('input').val(selectedPrice).change();

                    }

                    $('.the-color').find('input').val(selectedColor.replace(/_/g, ' '));
                }

                // sets the available colors for what product is currently selected
                function setAvailableColors(shirtStyle) {
                    $.ajax({
                        method: 'GET',
                        url: `/wp-json/cpl/v1/shirt/${shirtStyle}`
                    })
                    .then(function (response) {
                        console.log('in set available colors');
                        let colors = response;
                        $('#color-select-container').empty();

                        for (let color of colors) {
                            color = color.substring(
                                color.indexOf('_') + 1,
                                color.lastIndexOf('.'),
                            );

                            let html;
                            if (color == 'white') {
                                html = `
                            <input type="radio" name="color" id=${color} class="color-select" value=${color} checked="checked" >
                            <label for="${color}" class="color-select-label">
                                <span class="${color}"></span>
                            </label>`;
                            }
                            else {
                                html = `
                            <input type="radio" name="color" id=${color} class="color-select" value=${color} >
                            <label for="${color}" class="color-select-label">
                                <span class="${color}"></span>
                            </label>`;
                            }
                            $('#color-select-container').append(html);
                        }
                        selectedColor = $('body.digital-t-shirts [name="color"]:checked').val();
                        console.log('selected color', selectedColor);
                    })
                }
            }











            function updateButtonPrice() {
                let buttonQuantity = $('.button-quantity').find('input').val();
                let dynamicPrice = $('.button-dynamic-price').find('input').val();
                let buttonSize = $('.button-size').find('select option:checked').val();

                if (buttonSize == 1) {
                    if (buttonQuantity <= 5) {
                        $('.button-dynamic-price').find('input').val('2.50').change();
                    } else if (buttonQuantity > 5 && buttonQuantity <= 10) {
                        $('.button-dynamic-price').find('input').val('1.50').change();
                    } else if (buttonQuantity > 10 && buttonQuantity <= 25) {
                        $('.button-dynamic-price').find('input').val('1.45').change();
                    } else if (buttonQuantity > 25 && buttonQuantity <= 50) {
                        $('.button-dynamic-price').find('input').val('.60').change();
                    } else if (buttonQuantity > 50 && buttonQuantity <= 100) {
                        $('.button-dynamic-price').find('input').val('.55').change();
                    } else if (buttonQuantity > 100 && buttonQuantity <= 250) {
                        $('.button-dynamic-price').find('input').val('.41').change();
                    } else if (buttonQuantity > 250 && buttonQuantity <= 500) {
                        $('.button-dynamic-price').find('input').val('.37').change();
                    } else if (buttonQuantity > 500 && buttonQuantity <= 1000) {
                        $('.button-dynamic-price').find('input').val('.32').change();
                    } else if (buttonQuantity > 1000 && buttonQuantity <= 1500) {
                        $('.button-dynamic-price').find('input').val('.28').change();
                    } else if (buttonQuantity > 1500 && buttonQuantity <= 2500) {
                        $('.button-dynamic-price').find('input').val('.26').change();
                    } else if (buttonQuantity > 2500 && buttonQuantity <= 5000) {
                        $('.button-dynamic-price').find('input').val('.23').change();
                    } else if (buttonQuantity > 5000 && buttonQuantity <= 10000) {
                        $('.button-dynamic-price').find('input').val('.21').change();
                    } else if (buttonQuantity > 10000) {
                        $('.button-dynamic-price').find('input').val('.19').change();
                    }
                }

                if (buttonSize == 2) {
                    if (buttonQuantity <= 5) {
                        $('.button-dynamic-price').find('input').val('2.75').change();
                    } else if (buttonQuantity > 5 && buttonQuantity <= 10) {
                        $('.button-dynamic-price').find('input').val('1.70').change();
                    } else if (buttonQuantity > 10 && buttonQuantity <= 25) {
                        $('.button-dynamic-price').find('input').val('1.65').change();
                    } else if (buttonQuantity > 25 && buttonQuantity <= 50) {
                        $('.button-dynamic-price').find('input').val('.80').change();
                    } else if (buttonQuantity > 50 && buttonQuantity <= 100) {
                        $('.button-dynamic-price').find('input').val('.70').change();
                    } else if (buttonQuantity > 100 && buttonQuantity <= 250) {
                        $('.button-dynamic-price').find('input').val('.60').change();
                    } else if (buttonQuantity > 250 && buttonQuantity <= 500) {
                        $('.button-dynamic-price').find('input').val('.55').change();
                    } else if (buttonQuantity > 500 && buttonQuantity <= 1000) {
                        $('.button-dynamic-price').find('input').val('.47').change();
                    } else if (buttonQuantity > 1000 && buttonQuantity <= 1500) {
                        $('.button-dynamic-price').find('input').val('.35').change();
                    } else if (buttonQuantity > 1500 && buttonQuantity <= 2500) {
                        $('.button-dynamic-price').find('input').val('.32').change();
                    } else if (buttonQuantity > 2500 && buttonQuantity <= 5000) {
                        $('.button-dynamic-price').find('input').val('.27').change();
                    } else if (buttonQuantity > 5000 && buttonQuantity <= 10000) {
                        $('.button-dynamic-price').find('input').val('.24').change();
                    } else if (buttonQuantity > 10000) {
                        $('.button-dynamic-price').find('input').val('.20').change();
                    }
                }
            }

            function colorCopiesPrice() {

                /**
                 * Define all the pricing for the price breaks for paper types...
                 * */

                const pricingBreaks = [
                    {
                        name: 'Letter (8.5 by 11)',
                        singleValues: [35, 32, 29, 27, 25, 20, 18, 15, 12],
                        doubleValues: [70, 64, 58, 54, 50, 50, 36, 30, 24]
                    },
                    {
                        name: 'Legal (8.5 by 14)',
                        singleValues: [40, 37, 34, 32, 20, 20, 23, 20, 17],
                        doubleValues: [75, 74, 68, 364, 60, 60, 46, 40, 34]
                    },
                    {
                        name: 'Tabloid (11 by 17)',
                        singleValues: [45, 42, 39, 37, 35, 35, 28, 25, 22],
                        doubleValues: [85, 84, 78, 74, 70, 70, 56, 50, 44]
                    },
                    {
                        name: '12 by 18',
                        singleValues: [50, 47, 44, 42, 40, 40, 35, 30, 27],
                        doubleValues: [90, 94, 88, 84, 80, 80, 66, 60, 54]
                    },
                    {
                        name: '13 by 19',
                        singleValues: [55, 52, 49, 46, 41, 41, 34, 32, 29],
                        doubleValues: [100, 104, 95, 92, 8, 82, 68, 64, 58]
                    },
                    {
                        name: '8.5 by 5.5',
                        singleValues: [20, 18, 16, 14, 12, 12, 9, 7, 6],
                        doubleValues: [35, 36, 32, 28, 24, 24, 18, 14, 12]
                    },
                    {
                        name: '6 by 9',
                        singleValues: [35, 32, 29, 27, 25, 25, 18, 15, 12],
                        doubleValues: [70, 64, 58, 54, 50, 50, 36, 30, 24]
                    },
                    {
                        name: '5 by 7',
                        singleValues: [20, 18, 16, 14, 12, 12, 9, 7, 6],
                        doubleValues: [35, 36, 32, 28, 24, 24, 18, 14, 12]
                    },
                    {
                        name: 'Third Sheet',
                        singleValues: [20, 18, 16, 14, 12, 12, 9, 7, 6],
                        doubleValues: [35, 36, 32, 28, 24, 24, 18, 14, 12]
                    },
                    {
                        name: '4 by 6',
                        singleValues: [20, 18, 16, 14, 12, 12, 9, 7, 6],
                        doubleValues: [35, 36, 32, 28, 24, 24, 18, 14, 12]
                    },
                    {
                        name: '4.25 by 5.5 (quarter sheet)',
                        singleValues: [20, 18, 16, 14, 12, 12, 9, 7, 6],
                        doubleValues: [35, 36, 32, 28, 24, 24, 18, 14, 12]
                    },
                    {
                        name: '4.25 by 11',
                        singleValues: [20, 18, 16, 14, 12, 12, 9, 7, 6],
                        doubleValues: [35, 36, 32, 28, 24, 24, 18, 14, 12]
                    },
                ];

                /**
                 * Define all the dom elements that are going to be used
                 * Using the body + body class and name attribute selectors since they should
                 * be the same when importing the form in from your local dev to the remote dev.
                 * */
                let pageCount = $('body.color-copies [name="input_1"]');
                let sides = $('body.color-copies [name="input_3"]');
                let quantity = $('body.color-copies [name="input_2"]');
                const paperSize = $('body.color-copies [name="input_4"]');
                const paperType = $('body.color-copies [name="input_9"]');
                const frontCover = $('body.color-copies [name="input_7.1"]');
                const backCover = $('body.color-copies [name="input_7.2"]');
                const bleeds = $('body.color-copies [name="input_7.3"]');
                const drilling = $('body.color-copies [name="input_7.4"]');
                const uvVarnish = $('body.color-copies [name="input_7.5"]');
                const scoring = $('body.color-copies [name="input_19"]');
                const hardCopy = $('body.color-copies [name="input_7.7"]');
                const slipSheets = $('body.color-copies [name="input_7.8"]');
                const varData = $('body.color-copies [name="input_21"]');
                const padding = $('body.color-copies [name="input_20"]');
                const folding = $('body.color-copies [name="input_23"]');
                const frontCoverSides = $('body.color-copies [name="input_5"]');
                const backCoverSides = $('body.color-copies [name="input_8"]');
                const coverWeight = $('body.color-copies [name="input_10"]');
                const shrinkWrap = $('body.color-copies [name="input_11"]');
                const stapling = $('body.color-copies [name="input_17"]');

                const finalPrice = $('body.color-copies [name="input_12"]');



                /**
                 * // Loop through each element and add jquery listeners
                 * */
                const elements = [pageCount,sides,quantity,paperSize,paperType,frontCover,backCover,bleeds,drilling,
                uvVarnish,scoring,hardCopy,slipSheets,varData,padding,folding,frontCoverSides,backCoverSides,coverWeight,
                shrinkWrap,stapling];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () { // Make sure we get interactions.
                        priceUpdate();
                    });
                }
                priceUpdate();

                function priceUpdate() {
                    let price = 0;
                    let fees = 700; //Setup fee

                    sides = $('body.color-copies [name="input_3"]'); // Re-fetch the DOM elements to reconvert to number value
                    quantity = $('body.color-copies [name="input_2"]');
                    pageCount = $('body.color-copies [name="input_1"]');

                    sides = Number(sides.val()); // Convert our dom elements into numbers from their values to process below
                    quantity = Number(quantity.val());
                    pageCount = Number(pageCount.val());

                    let totalPages = quantity * pageCount;

                    if (totalPages >= 5000) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[8] : pricingBreaks[Number(paperSize.val())].doubleValues[8];
                    } else if (totalPages >= 3500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[7] : pricingBreaks[Number(paperSize.val())].doubleValues[7];
                    } else if (totalPages >= 2500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[6] : pricingBreaks[Number(paperSize.val())].doubleValues[6];
                    } else if (totalPages >= 2000) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[5] : pricingBreaks[Number(paperSize.val())].doubleValues[5];
                    } else if (totalPages >= 1500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[4] : pricingBreaks[Number(paperSize.val())].doubleValues[4];
                    } else if (totalPages >= 1000) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[3] : pricingBreaks[Number(paperSize.val())].doubleValues[3];
                    } else if (totalPages >= 500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[2] : pricingBreaks[Number(paperSize.val())].doubleValues[2];
                    } else if (totalPages >= 250) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[1] : pricingBreaks[Number(paperSize.val())].doubleValues[1];
                    } else {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[0] : pricingBreaks[Number(paperSize.val())].doubleValues[0];
                    }

                    price = price * totalPages;

                    //Bleeds
                    price = bleeds.is(":checked") ? price + (totalPages * 5) : price;
                    price = bleeds.is(":checked") ? price + (1200 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    //Slip sheets
                    price = slipSheets.is(":checked") ? price + totalPages : price;

                    //Paper type
                    price = price + (Number(paperType.val()) * totalPages);

                    //Covers
                    if (frontCover.is(":checked") && Number(frontCoverSides.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (frontCover.is(":checked") && Number(frontCoverSides.val()) === 2) {
                        price = price + (quantity * 70);
                    }
                    if (backCover.is(":checked") && Number(backCoverSides.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (backCover.is(":checked") && Number(backCoverSides.val()) === 2) {
                        price = price + (quantity * 70);
                    }

                    //Cover paper types
                    price = frontCover.is(":checked") ? price + (Number(coverWeight.val()) * totalPages) : price;
                    price = backCover.is(":checked") ? price + (Number(coverWeight.val()) * totalPages) : price;

                    //Stapling
                    price = Number(stapling.val()) !== 0 ? price + (Number(stapling.val()) * totalPages) : price;

                    //Drilling
                    price = drilling.is(":checked") ? price + (1200 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    //UV Varnish
                    let uv = totalPages * (sides === 1 ? 6 : 12); // # of sides
                    price = uvVarnish.is(":checked") ? price + (uv > 2500 ? uv : 2500) : price;

                    //Scores $10 fee & x per 1000 sheet
                    fees = Number(scoring.val()) !== 0 ? fees + 1000 : fees;
                    price = Number(scoring.val()) === 1 ? price + (1200 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;
                    price = Number(scoring.val()) === 2 ? price + (1500 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    //Shrink wrap
                    price = price + (Number(shrinkWrap.val()) * 200);

                    //VarData
                    fees = Number(varData.val()) > 0 ? fees + 3500 : fees;
                    price = Number(varData.val()) > 0 ? price + (3.5 * Number(varData.val())) : price;

                    //Hard Copy
                    price = hardCopy.is(":checked") ? price + 30 : price;

                    //Padded Sets
                    fees = Number(padding.val()) !== 0 ? fees + 1500 : fees;
                    price = Number(padding.val()) === 25 ? price + ((200 * (Math.floor(totalPages / Number(padding.val()))))) : price;
                    price = Number(padding.val()) === 50 ? price + ((250 * (Math.floor(totalPages / Number(padding.val()))))) : price;
                    price = Number(padding.val()) === 100 ? price + ((300 * (Math.floor(totalPages / Number(padding.val()))))) : price;

                    //Folding
                    price = Number(folding.val()) !== 0 ? price + (Number(folding.val()) * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    price = price / 100;
                    fees = fees / 100;

                    price = price + fees;

                    finalPrice.val(price).change();

                }
            }


            function bwCopiesPrice() {

                /**
                 * Define all the pricing for the price breaks for paper types...
                 * */

                const pricingBreaks = [
                    {
                        name: 'Letter (8.5 by 11)',
                        singleValues: [9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5],
                        doubleValues: [18, 17, 16, 15, 14, 13, 12, 11, 10]
                    },
                    {
                        name: 'Legal (8.5 by 14)',
                        singleValues: [11, 10.5, 10, 9.5, 9, 8.5, 8, 7.5, 7],
                        doubleValues: [22, 21, 20, 19, 18, 17, 16, 15, 14]
                    },
                    {
                        name: 'Tabloid (11 by 17)',
                        singleValues: [16.5, 16, 15.5, 15, 14.5, 14, 13.5, 13],
                        doubleValues: [33, 32, 31, 30, 29, 28, 27, 26]
                    },
                    {
                        name: '12 by 18',
                        singleValues: [19, 18.5, 18, 17.5, 17, 16.5, 16, 15.5, 15],
                        doubleValues: [38, 37, 36, 35, 34, 33, 32, 31, 30]
                    },
                    {
                        name: '13 by 19',
                        singleValues: [21, 20.5, 20, 19.5, 19, 18.5, 18, 17.5, 17],
                        doubleValues: [42, 41, 40, 39, 38, 37, 36, 35, 34]
                    },
                    {
                        name: '8.5 by 5.5',
                        singleValues: [5, 4.5, 4.5, 4, 4, 3.5, 3.5, 3, 3],
                        doubleValues: [10, 9, 9, 8, 8, 7, 7, 6, 6]
                    },
                    {
                        name: '6 by 9',
                        singleValues: [9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5],
                        doubleValues: [18, 17, 16, 15, 14, 13, 12, 11, 10]
                    },
                    {
                        name: '5 by 7',
                        singleValues: [5, 4.5, 4.5, 4, 4, 3.5, 3.5, 3, 3],
                        doubleValues: [10, 9, 9, 8, 8, 7, 7, 6, 6]
                    },
                    {
                        name: 'Third Sheet',
                        singleValues: [5, 4.5, 4.5, 4, 4, 3.5, 3.5, 3, 3],
                        doubleValues: [10, 9, 9, 8, 8, 7, 7, 6, 6]
                    },
                    {
                        name: '4 by 6',
                        singleValues: [5, 4.5, 4.5, 4, 4, 3.5, 3.5, 3, 3],
                        doubleValues: [10, 9, 9, 8, 8, 7, 7, 6, 6]
                    },
                    {
                        name: '4.25 by 5.5 (quarter sheet)',
                        singleValues: [5, 4.5, 4.5, 4, 4, 3.5, 3.5, 3, 3],
                        doubleValues: [10, 9, 9, 8, 8, 7, 7, 6, 6]
                    },
                    {
                        name: '4.25 by 11',
                        singleValues: [5, 4.5, 4.5, 4, 4, 3.5, 3.5, 3, 3],
                        doubleValues: [10, 9, 9, 8, 8, 7, 7, 6, 6]
                    },
                ];

                /**
                 * Define all the dom elements that are going to be used
                 * Using the body + body class and name attribute selectors since they should
                 * be the same when importing the form in from your local dev to the remote dev.
                 * */
                let pageCount = $('body.black-and-white-copies [name="input_1"]');
                let sides = $('body.black-and-white-copies [name="input_3"]');
                let quantity = $('body.black-and-white-copies [name="input_2"]');
                const paperSize = $('body.black-and-white-copies [name="input_4"]');
                const paperType = $('body.black-and-white-copies [name="input_9"]');
                const frontCover = $('body.black-and-white-copies [name="input_7.1"]');
                const backCover = $('body.black-and-white-copies [name="input_7.2"]');
                const bleeds = $('body.black-and-white-copies [name="input_7.3"]');
                const drilling = $('body.black-and-white-copies [name="input_7.4"]');
                const uvVarnish = $('body.black-and-white-copies [name="input_7.5"]');
                const scoring = $('body.black-and-white-copies [name="input_19"]');
                const hardCopy = $('body.black-and-white-copies [name="input_7.7"]');
                const slipSheets = $('body.black-and-white-copies [name="input_7.8"]');
                const ncrPaperTwo = $('body.black-and-white-copies [name="input_7.9"]');
                const ncrPaperThree = $('body.black-and-white-copies [name="input_7.11"]');
                const varData = $('body.black-and-white-copies [name="input_21"]');
                const padding = $('body.black-and-white-copies [name="input_20"]');
                const folding = $('body.black-and-white-copies [name="input_23"]');
                const frontCoverSides = $('body.black-and-white-copies [name="input_5"]');
                const backCoverSides = $('body.black-and-white-copies [name="input_8"]');
                const coverWeight = $('body.black-and-white-copies [name="input_10"]');
                const shrinkWrap = $('body.black-and-white-copies [name="input_11"]');
                const stapling = $('body.black-and-white-copies [name="input_17"]');

                const finalPrice = $('body.black-and-white-copies [name="input_12"]');



                /**
                 * // Loop through each element and add jquery listeners
                 * */
                const elements = [pageCount,sides,quantity,paperSize,paperType,frontCover,backCover,bleeds,drilling,
                    uvVarnish,scoring,hardCopy,slipSheets,varData,padding,folding,frontCoverSides,backCoverSides,coverWeight,
                    shrinkWrap,stapling,ncrPaperTwo,ncrPaperThree];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () { // Make sure we get interactions.
                        priceUpdateBW();
                    });
                }
                priceUpdateBW();

                function priceUpdateBW() {
                    let price = 0;
                    let fees = 700; //Setup fee

                    sides = $('body.black-and-white-copies [name="input_3"]'); // Re-fetch the DOM elements to reconvert to number value
                    quantity = $('body.black-and-white-copies [name="input_2"]');
                    pageCount = $('body.black-and-white-copies [name="input_1"]');

                    sides = Number(sides.val()); // Convert our dom elements into numbers from their values to process below
                    quantity = Number(quantity.val());
                    pageCount = Number(pageCount.val());

                    let totalPages = quantity * pageCount;

                    if (totalPages >= 5000) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[8] : pricingBreaks[Number(paperSize.val())].doubleValues[8];
                    } else if (totalPages >= 3500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[7] : pricingBreaks[Number(paperSize.val())].doubleValues[7];
                    } else if (totalPages >= 2500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[6] : pricingBreaks[Number(paperSize.val())].doubleValues[6];
                    } else if (totalPages >= 2000) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[5] : pricingBreaks[Number(paperSize.val())].doubleValues[5];
                    } else if (totalPages >= 1500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[4] : pricingBreaks[Number(paperSize.val())].doubleValues[4];
                    } else if (totalPages >= 1000) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[3] : pricingBreaks[Number(paperSize.val())].doubleValues[3];
                    } else if (totalPages >= 500) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[2] : pricingBreaks[Number(paperSize.val())].doubleValues[2];
                    } else if (totalPages >= 250) {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[1] : pricingBreaks[Number(paperSize.val())].doubleValues[1];
                    } else {
                        price = sides === 1 ? pricingBreaks[Number(paperSize.val())].singleValues[0] : pricingBreaks[Number(paperSize.val())].doubleValues[0];
                    }

                    price = price * totalPages;

                    //Bleeds
                    price = bleeds.is(":checked") ? price + (totalPages * 5) : price;
                    price = bleeds.is(":checked") ? price + (1200 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    //Slip sheets
                    price = slipSheets.is(":checked") ? price + totalPages : price;

                    //Paper type
                    price = price + (Number(paperType.val()) * totalPages);

                    //Covers
                    if (frontCover.is(":checked") && Number(frontCoverSides.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (frontCover.is(":checked") && Number(frontCoverSides.val()) === 2) {
                        price = price + (quantity * 70);
                    }
                    if (backCover.is(":checked") && Number(backCoverSides.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (backCover.is(":checked") && Number(backCoverSides.val()) === 2) {
                        price = price + (quantity * 70);
                    }

                    //Carbonless NCR Paper
                    price = ncrPaperThree.is(":checked") || ncrPaperTwo.is(":checked") ? price + (10 * totalPages) : price;

                    //Cover paper types
                    price = frontCover.is(":checked") ? price + (Number(coverWeight.val()) * totalPages) : price;
                    price = backCover.is(":checked") ? price + (Number(coverWeight.val()) * totalPages) : price;

                    //Stapling
                    price = Number(stapling.val()) !== 0 ? price + (Number(stapling.val()) * totalPages) : price;

                    //Drilling
                    price = drilling.is(":checked") ? price + (1200 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    //UV Varnish
                    let uv = totalPages * (sides === 1 ? 6 : 12); // # of sides
                    price = uvVarnish.is(":checked") ? price + (uv > 2500 ? uv : 2500) : price;

                    //Scores $10 fee & x per 1000 sheet
                    fees = Number(scoring.val()) !== 0 ? fees + 1000 : fees;
                    price = Number(scoring.val()) === 1 ? price + (1200 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;
                    price = Number(scoring.val()) === 2 ? price + (1500 * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    //Shrink wrap
                    price = price + (Number(shrinkWrap.val()) * 200);

                    //VarData
                    fees = Number(varData.val()) > 0 ? fees + 3500 : fees;
                    price = Number(varData.val()) > 0 ? price + (3.5 * Number(varData.val())) : price;

                    //Hard Copy
                    price = hardCopy.is(":checked") ? price + 30 : price;

                    //Padded Sets
                    fees = Number(padding.val()) !== 0 ? fees + 1500 : fees;
                    price = Number(padding.val()) === 25 ? price + ((200 * (Math.floor(totalPages / Number(padding.val()))))) : price;
                    price = Number(padding.val()) === 50 ? price + ((250 * (Math.floor(totalPages / Number(padding.val()))))) : price;
                    price = Number(padding.val()) === 100 ? price + ((300 * (Math.floor(totalPages / Number(padding.val()))))) : price;

                    //Folding
                    price = Number(folding.val()) !== 0 ? price + (Number(folding.val()) * (Math.floor(totalPages / 1000) > 1 ? Math.floor(totalPages / 1000) : 1)) : price;

                    price = price / 100;
                    fees = fees / 100;

                    price = price + fees;

                    finalPrice.val(price).change();

                }
            }




            function BookletPrice() {

                /**
                 * Define all the dom elements that are going to be used
                 * Using the body + body class and name attribute selectors since they should
                 * be the same when importing the form in from your local dev to the remote dev.
                 * */
                const color = $('body.booklets [name="input_19"]');
                const bwSize = $('body.booklets [name="input_21"]');
                const colorSize = $('body.booklets [name="input_1"]');
                const paper = $('body.booklets [name="input_8"]');
                const bleeds = $('body.booklets [name="input_2.1"]');
                const front = $('body.booklets [name="input_2.2"]');
                const back = $('body.booklets [name="input_2.3"]');
                const drilling = $('body.booklets [name="input_2.4"]');
                const hardCopy = $('body.booklets [name="input_2.5"]');
                const frontPaper = $('body.booklets [name="input_9"]');
                const backPaper = $('body.booklets [name="input_11"]');
                const coverWeight = $('body.booklets [name="input_6"]');
                const shrinkWrap = $('body.booklets [name="input_25"]');
                const varData = $('body.booklets [name="input_26"]');

                const unitPrice = $('body.booklets [name="input_24"]');
                const finalPrice = $('body.booklets [name="input_23"]');


                //Let these since their values will change later
                let quantity = $('body.booklets [name="input_17"]');
                let sides = $('body.booklets [name="input_4"]');


                /**
                 * // Loop through each element and add jquery listeners
                 * */
                const elements = [color, bwSize, colorSize, quantity, paper, sides, bleeds, front, back, frontPaper, backPaper, coverWeight, shrinkWrap, drilling, hardCopy, varData];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () { // Make sure we get interactions.
                        priceUpdate();
                    });
                }
                priceUpdate();

                function priceUpdate() {
                    let price = 0;
                    let fees = 700; //Setup fee

                    // Black and White or Color sheets..
                    let sheetPrice = Number(color.val()) === 1 ? Number(bwSize.val()) : Number(colorSize.val());

                    sides = $('body.booklets [name="input_4"]'); // Re-fetch the DOM elements to reconvert to number value
                    quantity = $('body.booklets [name="input_17"]');

                    sides = Number(sides.val()); // Convert our dom elements into numbers from their values to process below
                    quantity = Number(quantity.val());

                    if (Number(color.val()) === 1) {
                        if (quantity >= 5000) {
                            price = sides * (sheetPrice - 4);
                        } else if (quantity >= 3500) {
                            price = sides * (sheetPrice - 3.5);
                        } else if (quantity >= 2500) {
                            price = sides * (sheetPrice - 3);
                        } else if (quantity >= 2000) {
                            price = sides * (sheetPrice - 2.5);
                        } else if (quantity >= 1500) {
                            price = sides * (sheetPrice - 2);
                        } else if (quantity >= 1000) {
                            price = sides * (sheetPrice - 1.5);
                        } else if (quantity >= 500) {
                            price = sides * (sheetPrice - 1);
                        } else if (quantity >= 250) {
                            price = sides * (sheetPrice - .5);
                        } else {
                            price = sides * sheetPrice;
                        }
                    } else if (Number(color.val()) === 2) {
                        if (quantity >= 5000) {
                            price = sides * sheetPrice - 16;
                        } else if (quantity >= 3500) {
                            price = sides * sheetPrice - 14;
                        } else if (sides >= 2500) {
                            price = sides * sheetPrice - 12;
                        } else if (sides >= 2000) {
                            price = sides * sheetPrice - 10;
                        } else if (sides >= 1500) {
                            price = sides * sheetPrice - 8;
                        } else if (sides >= 1000) {
                            price = sides * sheetPrice - 6;
                        } else if (sides >= 500) {
                            price = sides * sheetPrice - 4;
                        } else if (sides >= 250) {
                            price = sides * sheetPrice - 2;
                        } else {
                            price = sides * sheetPrice;
                        }
                    }

                    price = bleeds.is(":checked") ? price + (sides * 5) : price;
                    fees = bleeds.is(":checked") ? fees + 1200 : fees;

                    price = price + (Number(paper.val()) + quantity);

                    if (front.is(":checked") && Number(frontPaper.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (front.is(":checked") && Number(frontPaper.val()) === 2) {
                        price = price + (quantity * 70);
                    }

                    if (back.is(":checked") && Number(backPaper.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (back.is(":checked") && Number(backPaper.val()) === 2) {
                        price = price + (quantity * 70);
                    }

                    price = front.is(":checked") ? price + (Number(coverWeight.val()) * quantity) : price;
                    price = back.is(":checked") ? price + (Number(coverWeight.val()) * quantity) : price;

                    fees = Number(varData.val()) > 0 ? fees + 3500 : fees;
                    price = Number(varData.val()) > 0 ? price + (3.5 * Number(varData.val())) : price;

                    price = price / 100;
                    fees = fees / 100;

                    unitPrice.val(price).change();

                    price = price * quantity;

                    price = drilling.is(":checked") && (pages % 1000 == 0) ? price + 12 : price;
                    price = hardCopy.is(":checked") ? price + 30 : price;

                    price = price + (Number(shrinkWrap.val()) * 2);

                    price = price + fees;

                    finalPrice.val(price).change();

                }
            }

            function PrintNBindPrice() {

                /**
                 * Define all the dom elements that are going to be used
                 * Using the body + body class and name attribute selectors since they should
                 * be the same when importing the form in from your local dev to the remote dev.
                 * */
                const color = $('body.print-and-bind [name="input_19"]');
                const bwSize = $('body.print-and-bind [name="input_21"]');
                const colorSize = $('body.print-and-bind [name="input_1"]');
                const paper = $('body.print-and-bind [name="input_8"]');
                const bleeds = $('body.print-and-bind [name="input_2.1"]');
                const front = $('body.print-and-bind [name="input_2.2"]');
                const back = $('body.print-and-bind [name="input_2.3"]');
                const frontPaper = $('body.print-and-bind [name="input_9"]');
                const backPaper = $('body.print-and-bind [name="input_11"]');
                const coverWeight = $('body.print-and-bind [name="input_6"]');
                const frontAcetate = $('body.print-and-bind [name="input_36.1"]');
                const backAcetate = $('body.print-and-bind [name="input_37.1"]');
                const backVinyl = $('body.print-and-bind [name="input_37.2"]');
                const drilling = $('body.print-and-bind [name="input_39.1"]');
                const shrinkWrap = $('body.print-and-bind [name="input_25"]');
                const hardCopy = $('body.print-and-bind [name="input_39.2"]');
                const binding = $('body.print-and-bind [name="input_43"]');
                const unitPrice = $('body.print-and-bind [name="input_24"]');
                const finalPrice = $('body.print-and-bind [name="input_23"]');

                //Let these since their values will change later
                let quantity = $('body.print-and-bind [name="input_17"]');
                let sides = $('body.print-and-bind [name="input_27"]');
                let pages = $('body.print-and-bind [name="input_30"]');


                /**
                 * // Loop through each element and add jquery listeners
                 * */
                const elements = [color, bwSize, colorSize, quantity, paper, pages, sides, bleeds, front, back, frontPaper, backPaper, coverWeight, frontAcetate, backAcetate, backVinyl, drilling, shrinkWrap, hardCopy, binding];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () { // Make sure we get interactions.
                        priceUpdate();
                    });
                }
                priceUpdate();

                function priceUpdate() {
                    let price = 0;
                    let fees = 700; //Setup fee
                    let color = $('body.print-and-bind [name="input_19"]');

                    // Black and White or Color sheets..
                    let sheetPrice = Number(color.val()) === 1 ? Number(bwSize.val()) : Number(colorSize.val());

                    sides = $('body.print-and-bind [name="input_27"]'); // Re-fetch the DOM elements to reconvert to number value
                    quantity = $('body.print-and-bind [name="input_17"]');
                    pages = $('body.print-and-bind [name="input_30"]');


                    sides = Number(sides.val()); // Convert our dom elements into numbers from their values to process below
                    quantity = Number(quantity.val());
                    pages = Number(pages.val());

                    if (Number(color.val()) === 1) {
                        if (pages >= 5000) {
                            price = pages * sides * (sheetPrice - 4);
                        } else if (pages >= 3500) {
                            price = pages * sides * (sheetPrice - 3.5);
                        } else if (pages >= 2500) {
                            price = pages * sides * (sheetPrice - 3);
                        } else if (pages >= 2000) {
                            price = pages * sides * (sheetPrice - 2.5);
                        } else if (pages >= 1500) {
                            price = pages * sides * (sheetPrice - 2);
                        } else if (pages >= 1000) {
                            price = pages * sides * (sheetPrice - 1.5);
                        } else if (pages >= 500) {
                            price = pages * sides * (sheetPrice - 1);
                        } else if (pages >= 250) {
                            price = pages * sides * (sheetPrice - .5);
                        } else {
                            price = pages * sides * sheetPrice;
                        }
                    } else if (Number(color.val()) === 2) {
                        if (pages >= 5000) {
                            price = pages * sides * (sheetPrice - 16);
                        } else if (pages >= 3500) {
                            price = pages * sides * (sheetPrice - 14);
                        } else if (pages >= 2500) {
                            price = pages * sides * (sheetPrice - 12);
                        } else if (pages >= 2000) {
                            price = pages * sides * (sheetPrice - 10);
                        } else if (pages >= 1500) {
                            price = pages * sides * (sheetPrice - 8);
                        } else if (pages >= 1000) {
                            price = pages * sides * (sheetPrice - 6);
                        } else if (pages >= 500) {
                            price = pages * sides * (sheetPrice - 4);
                        } else if (pages >= 250) {
                            price = pages * sides * (sheetPrice - 2);
                        } else {
                            price = pages * sides * sheetPrice;
                        }
                    }

                    price = bleeds.is(":checked") ? price + (pages * 5) : price;

                    //Add $12 per thousand for the cutting
                    fees = bleeds.is(":checked") && (pages % 1000 == 0) ? fees + 1200 : fees;

                    price = price + (Number(paper.val()) * pages);

                    //Check if cover options are checked and whether it is single/double sided
                    if (front.is(":checked") && Number(frontPaper.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (front.is(":checked") && Number(frontPaper.val()) === 2) {
                        price = price + (quantity * 70);
                    }

                    if (back.is(":checked") && Number(backPaper.val()) === 1) {
                        price = price + (quantity * 35);
                    } else if (back.is(":checked") && Number(backPaper.val()) === 2) {
                        price = price + (quantity * 70);
                    }

                    //Cover paper weight options
                    price = front.is(":checked") ? price + (Number(coverWeight.val()) * quantity) : price;
                    price = back.is(":checked") ? price + (Number(coverWeight.val()) * quantity) : price;
                    price = front.is(":checked") && frontAcetate.is(":checked") ? price + (Number(frontAcetate.val()) * quantity) : price;
                    price = back.is(":checked") && backAcetate.is(":checked") ? price + (Number(backAcetate.val()) * quantity) : price;
                    price = back.is(":checked") && backVinyl.is(":checked") ? price + (Number(backVinyl.val()) * quantity) : price;

                    unitPrice.val(price).change();

                    price = price / 100;
                    fees = fees / 100;

                    price = price * quantity;

                    if (Number(binding.val()) === 1) {
                        if (pages <= 100) {
                            price = price + 2.75 * quantity;
                        } else if (pages <= 250) {
                            price = price + 4.5 * quantity;
                        } else if (pages <= 500) {
                            price = price + 6 * quantity;
                        }
                    } else if (Number(binding.val()) === 2) {
                        if (pages <= 100) {
                            price = price + 3 * quantity;
                        } else if (pages <= 250) {
                            price = price + 5 * quantity;
                        } else if (pages <= 500) {
                            price = price + 7.5 * quantity;
                        }
                    }

                    price = drilling.is(":checked") && (pages % 1000 == 0) ? price + 12 : price;

                    price = hardCopy.is(":checked") ? price + 30 : price;

                    price = price + (Number(shrinkWrap.val()) * 2);

                    price = price + fees;

                    finalPrice.val(price).change();

                }
            }

            function PostcardsPrice() {

                /**
                 * Define all the dom elements that are going to be used
                 * Using the body + body class and name attribute selectors since they should
                 * be the same when importing the form in from your local dev to the remote dev.
                 * */
                const varnish = $('body.postcards [name="input_2.1"]');
                const bleed = $('body.postcards [name="input_2.2"]');
                const unitPrice = $('body.postcards [name="input_24"]');
                const finalPrice = $('body.postcards [name="input_23"]');

                //Let these since their values will change later
                let quantity = $('body.postcards [name="input_25"]');
                let size = $('body.postcards [name="input_21"]');
                let record = $('body.postcards [name="input_27"]');


                /**
                 * // Loop through each element and add jquery listeners
                 * */
                const elements = [varnish, bleed, quantity, size, record];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () { // Make sure we get interactions.
                        console.log('keyup');
                        priceUpdate();
                    });
                }
                priceUpdate();

                function priceUpdate() {
                    let price = 0;
                    let fees = 7; //Setup fee

                    size = $('body.postcards [name="input_21"]'); // Re-fetch the DOM elements to reconvert to number value
                    quantity = $('body.postcards [name="input_25"]');
                    record = $('body.postcards [name="input_27"]');

                    size = Number(size.val()); // Convert our dom elements into numbers from their values to process below
                    quantity = Number(quantity.val());
                    record = Number(record.val());

                    if (size == 1 || size == 2) {

                        if (quantity == 0) {
                            price = 87 / 100 * 50;
                        } else if (quantity == 1) {
                            price = 87;
                        } else if (quantity == 2) {
                            price = 199.5;
                        } else if (quantity == 3) {
                            price = 362;
                        } else if (quantity == 4) {
                            price = 692;
                        } else if (quantity == 5) {
                            price = 774;
                        } else if (quantity == 6) {
                            price = 870;
                        } else if (quantity == 7) {
                            price = 1250;
                        }

                    } else if (size == 3 || size == 4) {

                        if (quantity == 0) {
                            price = 56.55 / 100 * 50;
                        } else if (quantity == 1) {
                            price = 56.55;
                        } else if (quantity == 2) {
                            price = 129.68;
                        } else if (quantity == 3) {
                            price = 235.3;
                        } else if (quantity == 4) {
                            price = 449.8;
                        } else if (quantity == 5) {
                            price = 503.1;
                        } else if (quantity == 6) {
                            price = 565.5;
                        } else if (quantity == 7) {
                            price = 812.5;
                        }

                    } else if (size == 5) {

                        if (quantity == 0) {
                            price = 43.5 / 100 * 50;
                        } else if (quantity == 1) {
                            price = 43.5;
                        } else if (quantity == 2) {
                            price = 99.75;
                        } else if (quantity == 3) {
                            price = 181;
                        } else if (quantity == 4) {
                            price = 346;
                        } else if (quantity == 5) {
                            price = 387;
                        } else if (quantity == 6) {
                            price = 435;
                        } else if (quantity == 7) {
                            price = 625;
                        }

                    }

                    if (varnish.is(":checked")) {
                        if (quantity == 0) {
                            price = price + 25 / 100 * 50;
                        } else if (quantity == 1) {
                            price = price + 25;
                        } else if (quantity == 2) {
                            price = price + 30;
                        } else if (quantity == 3) {
                            price = price + 50;
                        } else if (quantity == 4) {
                            price = price + 80;
                        } else if (quantity == 5) {
                            price = price + 100;
                        } else if (quantity == 6) {
                            price = price + 130;
                        } else if (quantity == 7) {
                            price = price + 200;
                        }
                    }

                    if (bleed.is(":checked")) {
                        if (quantity == 0) {
                            price = price + 5 / 100 * 50;
                        } else if (quantity == 1) {
                            price = price + 5;
                        } else if (quantity == 2) {
                            price = price + 12;
                        } else if (quantity == 3) {
                            price = price + 25;
                        } else if (quantity == 4) {
                            price = price + 50;
                        } else if (quantity == 5) {
                            price = price + 62.5;
                        } else if (quantity == 6) {
                            price = price + 75;
                        } else if (quantity == 7) {
                            price = price + 125;
                        }
                    }

                    unitPrice.val(price).change();

                    price = (record > 0) ? price + 35 + (record * .035) : price;
                    price = price + fees;

                    finalPrice.val(price).change();

                }
            }

            function BookmarksPrice() {

                /**
                 * Define all the dom elements that are going to be used
                 * Using the body + body class and name attribute selectors since they should
                 * be the same when importing the form in from your local dev to the remote dev.
                 * */
                const twoSided = $('body.bookmarks [name="input_2.1"]');
                const bleed = $('body.bookmarks [name="input_2.2"]');
                const hardCopy = $('body.bookmarks [name="input_2.3"]');
                const drilling = $('body.bookmarks [name="input_2.4"]');
                const shrinkWrap = $('body.bookmarks [name="input_27"]');
                const unitPrice = $('body.bookmarks [name="input_24"]');
                const finalPrice = $('body.bookmarks [name="input_23"]');

                //Let these since their values will change later
                let quantity = $('body.bookmarks [name="input_25"]');
                let size = $('body.bookmarks [name="input_21"]');
                let color = $('body.bookmarks [name="input_26"]');


                /**
                 * // Loop through each element and add jquery listeners
                 * */
                const elements = [twoSided, bleed, hardCopy, drilling, shrinkWrap, quantity, size, color];
                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () { // Make sure we get interactions.
                        priceUpdate();
                    });
                }
                priceUpdate();

                function priceUpdate() {
                    let price = 0;
                    let fees = 700; //Setup fee

                    size = $('body.bookmarks [name="input_21"]'); // Re-fetch the DOM elements to reconvert to number value
                    quantity = $('body.bookmarks [name="input_25"]');
                    color = $('body.bookmarks [name="input_26"]');

                    size = Number(size.val()); // Convert our dom elements into numbers from their values to process below
                    quantity = Number(quantity.val());
                    color = Number(color.val());

                    if (color == 1 && size == 1) {

                        if (quantity == 1) {
                            price = 4825;
                        } else if (quantity == 2) {
                            price = 655;
                        } else if (quantity == 3) {
                            price = 9325;
                        } else if (quantity == 4) {
                            price = 1495;
                        } else if (quantity == 5) {
                            price = 18975;
                        } else if (quantity == 6) {
                            price = 2155;
                        } else if (quantity == 7) {
                            price = 2155 / 1500 * 2500;
                        }

                    } else if (color == 1 && size == 2) {

                        if (quantity == 1) {
                            price = 5308;
                        } else if (quantity == 2) {
                            price = 7205;
                        } else if (quantity == 3) {
                            price = 10258;
                        } else if (quantity == 4) {
                            price = 16445;
                        } else if (quantity == 5) {
                            price = 20873;
                        } else if (quantity == 6) {
                            price = 23705;
                        } else if (quantity == 7) {
                            price = 23705 / 1500 * 2500;
                        }

                    } else if (color == 2 && size == 1) {

                        if (quantity == 1) {
                            price = 24125;
                        } else if (quantity == 2) {
                            price = 3275;
                        } else if (quantity == 3) {
                            price = 46625;
                        } else if (quantity == 4) {
                            price = 7475;
                        } else if (quantity == 5) {
                            price = 94875;
                        } else if (quantity == 6) {
                            price = 10775;
                        } else if (quantity == 7) {
                            price = 10775 / 1500 * 2500;
                        }

                    } else if (color == 2 && size == 2) {

                        if (quantity == 1) {
                            price = 31845;
                        } else if (quantity == 2) {
                            price = 4323;
                        } else if (quantity == 3) {
                            price = 61545;
                        } else if (quantity == 4) {
                            price = 9867;
                        } else if (quantity == 5) {
                            price = 12524;
                        } else if (quantity == 6) {
                            price = 14223;
                        } else if (quantity == 7) {
                            price = 14223 / 1500 * 2500;
                        }

                    }

                    if (bleed.is(":checked")) {
                        if (quantity == 1) {
                            price = price + 250;
                        } else if (quantity == 2) {
                            price = price + 625;
                        } else if (quantity == 3) {
                            price = price + 1250;
                        } else if (quantity == 4) {
                            price = price + 2500;
                        } else if (quantity == 5) {
                            price = price + 3125;
                        } else if (quantity == 6) {
                            price = price + 3750;
                        } else if (quantity == 7) {
                            price = price + 3750 / 1500 * 2500;
                        }
                    }

                    if (color == 1 && twoSided.is(":checked")) {
                        if (quantity == 1) {
                            price = price + 1125;
                        } else if (quantity == 2) {
                            price = price + 2813;
                        } else if (quantity == 3) {
                            price = price + 5625;
                        } else if (quantity == 4) {
                            price = price + 11750;
                        } else if (quantity == 5) {
                            price = price + 15313;
                        } else if (quantity == 6) {
                            price = price + 21375;
                        } else if (quantity == 7) {
                            price = price + 21375 / 1500 * 2500;
                        }
                    }
                    if (color == 2 && twoSided.is(":checked")) {
                        if (quantity == 1) {
                            price = price + 300;
                        } else if (quantity == 2) {
                            price = price + 750;
                        } else if (quantity == 3) {
                            price = price + 1500;
                        } else if (quantity == 4) {
                            price = price + 3000;
                        } else if (quantity == 5) {
                            price = price + 3750;
                        } else if (quantity == 6) {
                            price = price + 4500;
                        } else if (quantity == 7) {
                            price = price + 4500 / 1500 * 2500;
                        }
                    }

                    if (hardCopy.is(":checked")) {
                        price = price + 3000;
                    }

                    if (drilling.is(":checked")) {
                        if (quantity <= 4) {
                            price = price + 1200;
                        } else {
                            price = price + 2400;
                        }
                    }

                    unitPrice.val(price).change();

                    price = price + (Number(shrinkWrap.val()) * 500);

                    price = price + fees;

                    price = price / 100;

                    finalPrice.val(price).change();

                }
            }

            function updateBusinessCardPrice() {

                let paperType = $('body.business-cards [name="input_19"]').val();
                let dynamicPrice = $('body.business-cards [name="input_31"]');
                let quantity = $('.quantity').find('select option:checked').val();

                // console.log('updating bc price', quantity);

                if (paperType == 1 || paperType == 2 || paperType == 3) {
                    // console.log('paper type', paperType);
                    if (quantity == 250 ) { dynamicPrice.val(.08).change(); }
                    if (quantity == 500 ) { dynamicPrice.val(.045).change(); }
                    if (quantity == 1000 ) { dynamicPrice.val(.025).change(); }
                    if (quantity == 2500 ) { dynamicPrice.val(.025).change(); }
                }
                if (paperType == 4) {
                    // console.log('paper type', paperType);
                    if (quantity == 250 ) { dynamicPrice.val(.1).change(); }
                    if (quantity == 500 ) { dynamicPrice.val(.055).change(); }
                    if (quantity == 1000 ) { dynamicPrice.val(.03).change(); }
                    if (quantity == 2500 ) { dynamicPrice.val(.03).change(); }
                }
                if (paperType == 5) {
                    // console.log('paper type', paperType);
                    if (quantity == 250 ) { dynamicPrice.val(.14).change(); }
                    if (quantity == 500 ) { dynamicPrice.val(.08).change(); }
                    if (quantity == 1000 ) { dynamicPrice.val(.045).change(); }
                    if (quantity == 2500 ) { dynamicPrice.val(.045).change(); }
                }
                if (paperType == 6) {
                    // console.log('paper type', paperType);
                    if (quantity == 250 ) { dynamicPrice.val(.12).change(); }
                    if (quantity == 500 ) { dynamicPrice.val(.065).change(); }
                    if (quantity == 1000 ) { dynamicPrice.val(.035).change(); }
                    if (quantity == 2500 ) { dynamicPrice.val(.035).change(); }
                }
                if (paperType == 7) {
                    // console.log('paper type', paperType);
                    dynamicPrice.val(1.5);
                }
            }




            function ShirtPrice() {
                console.log('in shirt price');

                let shirtType = $('.shirt-type');
                let gildanShirtStyle = $('.gildan-shirt-style');
                let genericShirtStyle = $('.generic-shirt-style');


                setAvailableColors(gildanShirtStyle.find('select option:checked').val());

                let selectedColor = $('.color-select:checked').val();

                const elements = [shirtType, gildanShirtStyle, genericShirtStyle];

                for (let i = 0; i < elements.length; i++) {
                    elements[i].on('change paste keyup', function () {
                        update();
                    });
                }

                $('#color-select-container').on('click', '.color-select-label', updateShirtColor);

                function update() {
                    if (shirtType.find('select option:checked').val() == 'Generic') {
                        $('#product-img').attr('src', `/wp-content/uploads/${genericShirtStyle.find('select option:checked').val()}_white.jpg`);
                        setAvailableColors(genericShirtStyle.find('select option:checked').val());
                    }
                    else {
                        $('#product-img').attr('src', `/wp-content/uploads/${gildanShirtStyle.find('select option:checked').val()}_white.jpg`);
                        setAvailableColors(gildanShirtStyle.find('select option:checked').val());
                    }
                }


                // update the shirt color when a new color is selected
                function updateShirtColor() {
                    let shirtURL = $('#product-img').attr('src');
                    let selectedGildanShirtStyle = $('.gildan-shirt-style').find('select option:checked').val();
                    let selectedGenericShirtStyle = $('.generic-shirt-style').find('select option:checked').val();

                    selectedColor = $(this).prev().val();

                    if (shirtType.find('select option:checked').val() == 'Generic') {
                        if (selectedGenericShirtStyle == 'onesie') {

                            shirtURL = `/wp-content/uploads/onesie_${selectedColor}.jpg`;
                            $('#product-img').attr('src', shirtURL);
                            $('#product-color-name').empty().append(selectedColor.replace(/_/g, ' '));

                        } else if (selectedGenericShirtStyle == 'toddler') {
                            shirtURL = `/wp-content/uploads/toddler_${selectedColor}.jpg`;
                            $('#product-img').attr('src', shirtURL);

                            $('#product-color-name').empty().append(selectedColor.replace(/_/g, ' '));

                        }
                    } else if (shirtType.find('select option:checked').val() == 'Gildan') {
                        shirtURL = `/wp-content/uploads/${selectedGildanShirtStyle}_${selectedColor}.jpg`;
                        $('#product-img').attr('src', shirtURL);
                        $('#product-color-name').empty().append(selectedColor.replace(/_/g, ' '));
                    }
                    $('.the-color').find('input').val(selectedColor.replace(/_/g, ' '));
                }

                // sets the available colors for what product is currently selected
                function setAvailableColors(shirtStyle) {
                    $.ajax({
                        method: 'GET',
                        url: `/wp-json/cpl/v1/shirt/${shirtStyle}`
                    })
                      .then(function (response) {
                          let colors = response;
                          console.log("here");
                          $('#color-select-container').empty();

                          for (let color of colors) {
                              color = color.substring(
                                color.indexOf('_') + 1,
                                color.lastIndexOf('.'),
                              );

                              console.log('color', color);

                              let html;
                              if (color == 'white') {
                                  html = `
                                        <input type="radio" name="color" id=${color} class="color-select" value=${color} checked="checked" >
                                        <label for="${color}" class="color-select-label">
                                            <span class="${color}"></span>
                                        </label>`;
                              }
                              else {
                                  html = `
                                        <input type="radio" name="color" id=${color} class="color-select" value=${color} >
                                        <label for="${color}" class="color-select-label">
                                            <span class="${color}"></span>
                                        </label>`;
                              }
                              $('#color-select-container').append(html);
                          }
                      })
                }

                function updateShirtPrice(selectedColor) {
                    if (selectedColor !== 'white') {
                        $('.is-color select').val('6').change();
                    } else {
                        $('.is-color select').val('0').change();
                    }
                }

            }

        })(jQuery);

    },
};